import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { assertUnreachable, classNames, prettyUrl } from "@/lib/utils";
import { AutocompleteEntity } from "@/types";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useRouter as legacyUseRouter } from "next/compat/router";
import { useRouter } from "next/navigation";

type DefaultItemType = {
  id: string;
  name: string;
  url?: string;
  source?: string;
  subtitle?: string;
};

export enum AutocompleteVariant {
  Entities = "entities",
  Default = "default",
}

type GenericProps<T> = {
  items: T[];
  customIcon?: React.ReactNode;
  injectAfterOptions?: React.ReactNode;
  initialValue?: T;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  magnifyClassName?: string;
  onSelect: (item: T) => void;
  onQueryChange?: (query: string) => void;
  cantFindPrefix?: string;
  allowEmptyQuerySelect?: boolean;
};

type EntityVariant = {
  variant: AutocompleteVariant.Entities;
} & GenericProps<AutocompleteEntity>;

type DefaultVariant = {
  variant: AutocompleteVariant.Default;
} & GenericProps<DefaultItemType>;

type Props = EntityVariant | DefaultVariant;

type WrappedAutocompleteEntity<T> = {
  id: string;
  entity: T;
};

export default function AutocompleteBox({
  items,
  variant,
  className,
  inputClassName,
  initialValue,
  placeholder,
  autoFocus,
  magnifyClassName,
  onSelect,
  onQueryChange,
  customIcon,
  cantFindPrefix,
  allowEmptyQuerySelect,
  injectAfterOptions,
}: Props) {
  const [query, setQuery] = useState<string | undefined>(undefined);

  const comboBtn = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (autoFocus && comboBtn.current) {
      comboBtn.current.click();
    }
  }, [autoFocus]);

  // Apparently the combobox of headless ui does not react well to changes
  // in its `immediate` prop. However, it does react well to changes in its
  // `disabled` prop. That's why if we want to change the `immediate` prop
  // we need to set the `disabled` prop to true, wait a tick, and then set
  // the `immediate` prop to the new value.
  // https://github.com/tailwindlabs/headlessui/issues/3659
  const [immediate, setImmediate] = useState<boolean>(true);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    const newImmediate = !!injectAfterOptions;
    if (newImmediate !== immediate) {
      setDisabled(true);
      const timeout = setTimeout(() => {
        setImmediate(newImmediate);
        setDisabled(false);
      }, 100);
      return () => {
        clearTimeout(timeout);
        setDisabled(false);
      };
    }
  }, [injectAfterOptions, immediate]);

  const wrappedOnSelect = (item: WrappedAutocompleteEntity<Parameters<typeof onSelect>[0]>) => {
    // This typecast is wrong, but I think I am hitting a TS error.
    // onSelect has two potential types, decided on the variant. I am not sure
    // why it wants me to cast it to this type, while the argument type
    // already binds it precisely to its argument type.
    onSelect(item?.entity as AutocompleteEntity);
  };

  // Items being passed to this component sometimes are lacking id, which is
  // necessary for this component to work. This wraps the incoming items
  // and makes sure they all have an id. It unwraps the items with onSelect.
  const wrappedItems: WrappedAutocompleteEntity<Parameters<typeof onSelect>[0]>[] = items.map(
    (item) => ({
      id:
        item.id ? "id_" + item.id
        : item.url ? "url_" + item.url
        : "name_" + item.name,
      entity: item,
    }),
  );

  return (
    <Combobox
      id="autocompleteBox"
      as="div"
      immediate={immediate}
      disabled={disabled}
      onChange={wrappedOnSelect}
      defaultValue={wrappedItems.find((i) => i.entity === initialValue)}
      className={classNames("relative", className)}
    >
      {customIcon ?
        customIcon
      : <MagnifyingGlassIcon
          className={classNames("absolute w-5 h-5 text-gray-400 left-2 top-2", magnifyClassName)}
        />
      }

      <ComboboxInput<{ name: string }>
        className={twMerge(
          "w-full rounded-md border-0 bg-white py-1.5 pl-8 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6",
          inputClassName,
        )}
        onChange={(event) => {
          setQuery(event.target.value);
          onQueryChange?.(event.target.value);
        }}
        displayValue={(item) => item?.name}
        placeholder={placeholder}
        value={query ?? initialValue?.name}
        autoComplete="off"
      />

      <ComboboxButton className="hidden" ref={comboBtn} />
      <ComboboxOptions
        className={classNames(
          "fixed left-0",
          "md:absolute md:top-auto",
          "mt-1 z-10 w-full max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
        )}
      >
        {((query !== undefined && query.length > 0) || allowEmptyQuerySelect) && (
          <ComboboxOption
            className={({ focus }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                focus ? "bg-brand-600 text-white" : "text-gray-900",
              )
            }
            value={{ id: null, entity: { name: query } }}
          >
            {!!query?.length && (
              <>
                {cantFindPrefix || "Search for"} <span className="font-semibold">{query}</span>
              </>
            )}
            {query?.length === 0 && <span>Search for something...</span>}
          </ComboboxOption>
        )}
        {(() => {
          switch (variant) {
            case AutocompleteVariant.Entities: {
              return wrappedItems.map((item) => (
                <Option
                  key={item.id}
                  variant={variant}
                  item={item as WrappedAutocompleteEntity<AutocompleteEntity>}
                />
              ));
            }
            case AutocompleteVariant.Default: {
              return wrappedItems.map((item) => (
                <Option key={item.id} variant={variant} item={item} />
              ));
            }
            default: {
              const _exhaustiveCheck: never = variant;
              assertUnreachable(_exhaustiveCheck);
            }
          }
        })()}
        {injectAfterOptions || <></>}
      </ComboboxOptions>
    </Combobox>
  );
}

const Option = ({
  variant,
  item,
}:
  | { variant: AutocompleteVariant.Entities; item: WrappedAutocompleteEntity<AutocompleteEntity> }
  | { variant: AutocompleteVariant.Default; item: WrappedAutocompleteEntity<DefaultItemType> }) => {
  return (
    <ComboboxOption
      key={item.id}
      value={item}
      className={({ focus }) => {
        return classNames(
          "relative cursor-default select-none py-2 pl-3",
          focus ? "bg-brand-600 text-white" : "text-gray-900",
        );
      }}
    >
      {({ active, selected }) => (
        <span className={classNames("truncate flex gap-2 content-center items-center")}>
          {variant === AutocompleteVariant.Entities && (
            <EntityIconWithPlaceholder
              className="w-6 h-6 overflow-hidden flex-shrink-0"
              imageClassName="w-6 h-6 object-cover"
              entity={item.entity}
            />
          )}
          {item.entity.name}
          <span className="truncate">
            {item.entity.subtitle || null}
            {!item.entity.subtitle && item.entity.url && ` (${prettyUrl(item.entity.url)})`}
          </span>
          <div className="grow" />
        </span>
      )}
    </ComboboxOption>
  );
};
