import API from "@/client/api";
import LinkedInIcon from "@/components/ui/LinkedInIcon";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useCachedAPI } from "@/hooks/swr";
import {
  ageFromBirthYear,
  isPersonOrCompanyLinkedInUrl,
  parsePersonOrCompanyLinkedInProfile,
  prettyUrl,
  smartTruncate,
} from "@/lib/stringUtils";
import { FoundEntity } from "@/stores/searchStore";
import {
  CompanyCardData,
  Entity,
  EntityType,
  GenericProfile,
  PersonCardData,
  PersonCompanyRelationship,
  RelationshipDataWorkedAt,
} from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import React, { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

interface Props {
  foundEntity: FoundEntity;
  actualEntity?: Entity;
}

function SearchResultsRow({ foundEntity, actualEntity }: Props) {
  // With Tailwind 3 it's impossible to create a group-not-has combo, which we would
  // need in order to share hover state to children, but exclude some of them.
  // That's why we need to use a separate state.
  const [externalLinkHovered, setExternalLinkHovered] = useState(false);

  const linkDest =
    actualEntity ? entityUrl(actualEntity)
    : foundEntity.type == EntityType.Unknown ? foundEntity.entityUrl
    : `/profile?url=${foundEntity.entityUrl}`;

  const isLinkedInURL = isPersonOrCompanyLinkedInUrl(foundEntity.pageUrl);
  const externalLinkToShow =
    isLinkedInURL ?
      `/${parsePersonOrCompanyLinkedInProfile(foundEntity.pageUrl)}`
    : prettyUrl(foundEntity.pageUrl);

  const { data: cardData } = useCachedAPI(API.getCardData, "getCardData", () => {
    return actualEntity ? ([{ entityId: actualEntity.id }] as [{ entityId: string }]) : false;
  });

  return (
    <Link
      className={twMerge(
        "flex gap-3 p-3 items-center",
        externalLinkHovered ? "" : "group hover:cursor-pointer",
      )}
      href={linkDest}
    >
      <EntityIconWithPlaceholder
        className="h-12 w-12"
        entity={{ id: foundEntity.pageUrl, url: foundEntity.entityUrl, ...foundEntity }}
      />
      <div className="flex flex-1 flex-row items-center">
        <h3
          className={twJoin(
            "self-center text-sm font-medium group-hover:underline w-[20%] mr-2 flex-grow-0 flex-shrink-0",
            actualEntity ? "text-brand-700" : "text-gray-900",
          )}
          data-tooltip-content={actualEntity ? "Has Distill Profile" : undefined}
          data-tooltip-id="tooltip"
        >
          {smartTruncate(foundEntity.name, 40)}
          {foundEntity.degree && <span className="ml-1">&bull; {foundEntity.degree}</span>}
        </h3>
        <div className="text-sm text-gray-500 whitespace-pre-wrap w-[60%] flex-grow-0 flex-shrink-0 max-h-full overflow-hidden">
          {smartTruncate(getSearchRowDescription(foundEntity, cardData) || "", 150)}
        </div>
        <div className="ml-2 justify-center sm:justify-start pr-4 flex flex-row whitespace-nowrap w-[20%] min-w-10 flex-grow-0 flex-shrink-0 overflow-hidden">
          <span
            className="inline-flex items-center text-gray-700 rounded-full text-xs font-medium hover:text-brand-600 hover:underline"
            rel="noreferrer"
            onMouseOver={(e) => {
              e.stopPropagation();
              setExternalLinkHovered(true);
            }}
            onMouseOut={() => setExternalLinkHovered(false)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.open(foundEntity.pageUrl, "_blank");
            }}
          >
            {isLinkedInURL && <LinkedInIcon className="h-4 w-4 mr-1 flex-shrink-0 self-center" />}
            <span className="hidden sm:inline">
              {smartTruncate(decodeURIComponent(externalLinkToShow), 20)}
            </span>
            {!isLinkedInURL && <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 flex-shrink-0" />}
          </span>
        </div>
      </div>
    </Link>
  );
}

export const getSearchRowDescription = (
  foundEntity: FoundEntity,
  cardData: PersonCardData | CompanyCardData | undefined,
): string | undefined => {
  let description = foundEntity.description;

  const personCardData =
    cardData?.entity.type === EntityType.Person ? (cardData as PersonCardData) : undefined;
  const companyCardData =
    cardData?.entity.type === EntityType.Company ? (cardData as CompanyCardData) : undefined;

  if (personCardData) {
    const title = personCardData.currentPosition || personCardData.entity.title || "";
    const company = personCardData.currentCompany;
    const location = personCardData.location;
    if (title && company) {
      description = `${title}, ${company}`;
      if (location) {
        description += ` · ${location}`;
      }
    }
  }

  if (companyCardData) {
    const facts = [];
    if (companyCardData.website) {
      facts.push(prettyUrl(companyCardData.website));
    }
    if (companyCardData.employees) {
      facts.push(`Employees: ${companyCardData.employees}`);
    }
    if (companyCardData.location) {
      facts.push(companyCardData.location);
    }
    if (companyCardData.founded) {
      facts.push(`Founded: ${companyCardData.founded}`);
    }
    if (facts.length > 0) {
      description = facts.join(" · ");
    }
  }

  return description;
};

export default React.memo(SearchResultsRow);
