import { cachedImage } from "@/lib/utils";
import { useState } from "react";
import Gravatar from "react-gravatar";
import { twMerge } from "tailwind-merge";
import uniqolor from "uniqolor";

export default function Avatar({
  user,
  className,
  tooltip,
}: {
  user: { id?: string; name?: string | null; email?: string | null; image?: string | null };
  className?: string;
  tooltip?: string;
}) {
  const [hasError, setHasError] = useState(false);

  if (!user.image || hasError) {
    const initials = makeInitials(user);

    const color = uniqolor(user.id || user.email || user.name || "?", {
      lightness: [20, 70],
    });

    return (
      <div
        className="relative"
        data-tooltip-content={tooltip || user.name || "Unknown"}
        data-tooltip-id="tooltip"
      >
        {user.email && (
          <div className="absolute top-0 left-0 w-full h-full">
            <Gravatar
              email={user.email}
              size={100}
              default="blank"
              className={twMerge("rounded", className)}
              protocol="https://"
            />
          </div>
        )}
        <span
          className={twMerge(
            "inline-flex h-8 w-8 items-center justify-center rounded bg-gray-500",
            className,
          )}
          style={{ backgroundColor: color.color }}
        >
          <span className="text-normal font-medium leading-none text-white">{initials}</span>
        </span>
      </div>
    );
  }

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      data-tooltip-content={tooltip || user.name || "Unknown User"}
      data-tooltip-id="tooltip"
      className={twMerge("inline-block h-8 w-8 rounded-full", className)}
      src={cachedImage(user.image)}
      alt={user.name || "User"}
      onError={() => {
        setHasError(true);
      }}
    />
  );
}

export function makeInitials(user: { name?: string | null; email?: string | null }) {
  const str = user.name || user.email || "";

  if ((str.codePointAt(0) || 0) > 255) {
    // if it's a non-ascii character, use the first two characters
    return str.slice(0, 2).toUpperCase();
  }

  return (
    (user.name || user.email || "")
      .toUpperCase()
      .replace(/\(.*\)/g, "")
      .replace(" AND ", " ")
      .replace(" OF ", " ")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .split(" ", 2)
      .map((n) => n.slice(0, 1))
      .slice(0, 2)
      .join("") || ""
  );
}
