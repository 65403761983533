import API from "@/client/api";
import useSWR, { SWRConfig, SWRConfiguration, SWRResponse, useSWRConfig } from "swr";

// Use any time you want to use SWR with our API.
//
// example usage:
// const { data, error, isLoading } = useCachedAPI(API.getCardData, "getCardData", [{entityId}]);
// The first argument is the API call you want to use, the second is its path within API - it's needed
// to maintain the cache for various calls.
//
// args need to be serializable. If you pass a function - it wil be evaluated to args. If it's false,
// the call will not be made.
//
// Follow https://swr.vercel.app/docs for more info.
// eslint-disable-next-line @typescript-eslint/max-params
export function useCachedAPI<Args extends unknown[], Value>(
  apiCall: (...args: Args) => Value,
  apiPath: string,
  args: Args | (() => Args | false),
  options?: SWRConfiguration,
): SWRResponse<Awaited<Value>> {
  if (!verifyAPICallPath(apiPath, apiCall)) {
    throw new Error(`apiCall does not match apiPath ${apiPath}`);
  }
  const keyFn = () => {
    const realArgs = typeof args === "function" ? args() : args;
    return realArgs ? [apiPath, realArgs] : null;
  };
  const result: SWRResponse<Awaited<Value>, unknown, unknown> = useSWR(keyFn, null, options);
  return result;
}

export function useInvalidateCachedAPI(
  apiPath: string,
  args: unknown[] | (() => unknown[] | false),
) {
  const { mutate } = useSWRConfig();
  const keyFn = () => {
    const realArgs = typeof args === "function" ? args() : args;
    return realArgs ? [apiPath, realArgs] : null;
  };
  return () => mutate(keyFn, undefined, { revalidate: true });
}

function verifyAPICallPath(apiPath: string, apiCall: unknown) {
  return apiCall === getAPICall(apiPath);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAPICall(apiPath: string): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return apiPath.split(".").reduce((acc: any, part) => acc?.[part], API);
}

// Fetcher within SWR uses any type, and we don't know what types will be passed
// around so we need to disable eslint. The correct type checking is verified
// at the useCachedAPI level.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function swrFetcher([apiPath, args]: [string, any[]]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const apiCall = getAPICall(apiPath);
  if (!apiCall) {
    // Should never happen, because we verify the path when creating the key.
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return apiCall(...args);
}

export function GlobalSWRConfig(props: { children: React.ReactNode }) {
  return (
    // By default, SWR will be immutable. If you want to use the mutable version,
    // you can override some of the options in your call to useCachedApi.
    <SWRConfig
      value={{
        fetcher: swrFetcher,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      {props.children}
    </SWRConfig>
  );
}
